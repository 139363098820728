import * as React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { GlobalContext } from '../GlobalState'
import { Authority } from '../util/Authority'
import { UrlParams } from '../util/UrlParams'
import { HELP_LINKS, PAGE_TITLES } from '../constants'
import { usePageState } from './PageReport/state'
import { colors } from '../styleConstants'
import { PageLayout } from '../components/layout/PageLayout'
import { PageGrid } from '../components/grid/PageGrid'
import {
  PageReportItem,
  PageReportSortKey,
  PageReportSortState,
  gridLayout,
  gridLayoutRowGroups,
} from '../components/grid/PageGridItem'
import { Loading } from '../components/common/Loading'
import { Paginate } from '../components/common/Paginate'
import { Select } from '../components/common/Select'
import { Input } from '../components/common/Input'
import { PageReportNarrowDown } from '../components/filter/PageReportNarrowDown'
import { CustomFilterUseContext } from '../components/filter/CustomFilter'
import { PageHeader } from '../components/common/PageHeader'
import { Welcome } from '../components/modal/Welcome'
import { PvLimitErrorBox } from '../components/common/PvLimitErrorBox'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { CloseCircle } from '@styled-icons/ionicons-sharp/CloseCircle'
import { HelpLink } from '../components/common/HelpLink'
import {
  INIT_PAGE_REPORTS_SUMMARY,
  makeErrorMessage,
  PageReportsSummary,
  usePageReport,
} from '../util/hooks/api/usePageReport'
import { Toolbar } from '../components/common/Toolbar'
import { DeviceType, setSearchParamsDeviceType, useDeviceType } from '../util/hooks/useDeviceType'
import { NotSettingsMessages } from '../components/reportMessages/NotSettingsMessages'
import { useReportSettings } from '../util/hooks/useReportSettings'
import { WarningBox } from '../components/warnings/WarningBox'
import { ErrorBox } from '../components/common/ErrorBox'
import { CanNotViewMessage } from '../components/warnings/CanNotViewMessage'
import { UrlParamsErrorMessages } from '../components/reportMessages/UrlParamsErrorMessages'
import { ReportContext } from '../contexts/ReportProvider'
import { PageCapturedCheckbox } from '../components/report/filters/PageCapturedCheckbox'
import { CustomFilterContext } from '../contexts/CustomFilterContext'
import { usePageReportCsvDownload } from '../util/hooks/api/usePageReportCsvDownload'
import { TimelineChart } from './PageReport/TimelineChart'
import { BarChartAlt } from '@styled-icons/boxicons-regular'
import { usePageReportTimelineCsvDownload } from '../util/hooks/api/usePageReportTimelineCsvDownload'
import { HeaderShortAccordionBox } from '../components/common/AccordionBox'
import { useTimelineOpened } from '../util/hooks/cookie/useTimelineOpened'
import { useTimelineReport } from '../util/hooks/api/useTimelineReport'

interface PageReportProps extends RouteComponentProps {
  projectId?: string
}

export function PageReport(props: PageReportProps) {
  const projectId = props.projectId || ''

  const {
    state: { pageReportState, AccountInfo },
    actions: globalActions,
  } = React.useContext(GlobalContext)
  const {
    state: { calenderState, teamPvLimit, teamPvCount },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState, openedFilter },
    actions: customFilterActions,
  } = React.useContext(CustomFilterContext)

  const { deviceType, setDeviceType } = useDeviceType()
  const { timelineOpened, setTimelineOpened } = useTimelineOpened()

  const { isCompleted: isSettingsCompleted, isChecking: isSettingsChecking } = useReportSettings({
    projectId: Number(projectId),
  })
  const containerRef = React.useRef<HTMLDivElement>(null)

  const { state, actions } = usePageState()
  const {
    initialized,
    loading,
    sortState,
    page,
    currentPage,
    itemCount,
    dispCount,
    openedNarrowDown,
    disabledNarrowDown,
    searchText,
    isSearch,
    narrowDownTemp,
    errorMessage,
    narrowDown,
  } = state

  const {
    mutate: reportCsvDownloadMutate,
    isLoading: isReportCsvDownLoading,
    isError: isReportCsvDownloadError,
  } = usePageReportCsvDownload({
    projectId,
    calenderState,
    sortState,
    searchText,
    narrowDownState: narrowDown,
    customFilterState,
  })

  const {
    mutate: timelineCsvDownloadMutate,
    isLoading: isTimelineCsvDownLoading,
    isError: isTimelineCsvDownloadError,
  } = usePageReportTimelineCsvDownload({
    projectId,
    searchText,
    narrowDownState: narrowDown,
  })

  const [searchTextTemp, setSearchTextTemp] = React.useState(pageReportState.searchText) // 検索オプションに1文字入力毎にAPI refetchしないようにするため、tempを利用
  const [warningMessage, setWarningMessage] = React.useState('')

  // 初回訪問かどうかのチェック
  const firstVisit = !!(props.location?.state as any)?.firstVisit
  // インデックス計算
  const itemCountFrom = 1 + currentPage * dispCount
  const itemCountTo = Math.min(itemCount, itemCountFrom + dispCount - 1)

  React.useEffect(() => {
    if (isSettingsChecking) return
    actions.initialize(
      projectId,
      pageReportState.dispCount,
      pageReportState.sortState,
      pageReportState.searchText,
      pageReportState.narrowDownState,
      isSettingsCompleted,
    )
  }, [isSettingsChecking])

  const {
    data: pageReportData,
    isError: isPageReportError,
    isLoading: isPageReportDataLoading,
  } = usePageReport({
    projectId,
    calenderState,
    limit: dispCount,
    offset: currentPage * dispCount || 0,
    sortState,
    searchText,
    narrowDownState: narrowDown,
    customFilterState,
    enabled: initialized,
  })

  const { isError: isTimelineReportError } = useTimelineReport({
    projectId: Number(projectId),
    searchText,
    narrowDownState: narrowDown,
  })

  const pageReportItems: PageReportItem[] = pageReportData ? pageReportData.reportItems : []
  const pageReportsSummary: PageReportsSummary = pageReportData
    ? pageReportData.pageReportsSummary
    : INIT_PAGE_REPORTS_SUMMARY

  React.useEffect(() => {
    if (!pageReportData) return
    if (pageReportData.count === 0) {
      setWarningMessage(makeErrorMessage(searchText, narrowDown, customFilterState))
    } else {
      setWarningMessage('')
    }
    actions.onSuccessFetchPageReports(pageReportData.count)
    scrollTop()
  }, [pageReportData])

  React.useEffect(() => {
    if (isPageReportError) {
      actions.onErrorFetchPageReports()
    }
  }, [isPageReportError])

  // 通常検索実行
  const handleSearchApply = (event: any) => {
    event.preventDefault()
    actions.search.search(searchTextTemp)
    globalActions.setPageReportSearchText(searchTextTemp)
  }

  const handleSearchUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchTextTemp(event.currentTarget.value)
  }

  // 通常検索条件クリア
  const handleSearchClear = () => {
    actions.search.clear()
    setSearchTextTemp('')
    globalActions.setPageReportSearchText('')
  }
  // 絞り込み実行
  const handleNarrowDownApply = () => {
    const state = actions.narrowdown.apply()
    // 高度な絞り込み実行時には入力フォームはクリアする
    setSearchTextTemp('')
    globalActions.setPageReportSearchText('')
    globalActions.setPageReportNarrowDown(state)
  }
  // 絞り込み解除
  const handleNarrowDownReset = () => {
    actions.narrowdown.reset()
    globalActions.setPageReportNarrowDown([])
  }

  const handleDeviceChange = (deviceType: DeviceType) => {
    setSearchParamsDeviceType(deviceType)
    setDeviceType(deviceType)
    actions.changeDevice()
  }

  const handleSortChange = (key: PageReportSortKey) => {
    const newSortState: PageReportSortState = actions.onSort(key)
    globalActions.savePageReportSortState(newSortState)
  }

  const handlePaginateChange = (selectedItem: { selected: number }) => {
    actions.onPaginateChange(selectedItem.selected)
  }

  // 表示件数変更
  const handleDispChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value)
    history.replaceState(null, '', UrlParams.createDispParam(new URL(window.location.href), value))
    actions.onDispCountChange(value)
    globalActions.onPageReportDispChange(value)
  }

  const handleReportCsvDownloadClick = async () => {
    if (!Authority.canDownloadReport(AccountInfo.permissions, projectId)) {
      alert('現在のプランではご利用いただけません')
      return
    }
    reportCsvDownloadMutate()
  }

  const handleTimelineCsvDownloadClick = async () => {
    if (!Authority.canDownloadReport(AccountInfo.permissions, projectId)) {
      alert('現在のプランではご利用いただけません')
      return
    }
    timelineCsvDownloadMutate()
  }

  const handleTimelineAccordionChange = (_event: React.SyntheticEvent, expanded: boolean) => {
    setTimelineOpened(expanded)
  }

  const scrollTop = () => {
    containerRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const baseUrl = props.uri?.split(props.path!).join('') ?? ''
  const title = `${PAGE_TITLES.PAGE_REPORT} | ${AccountInfo.projectName} | ${AccountInfo.teamName}チーム`

  const isLoading =
    (!firstVisit && loading) ||
    isSettingsChecking ||
    // レポートがまだ無い初期ページの場合、isPageReportDataLoadingだけだとローディングが出てしまうため「isSettingsCompleted」の条件を追加した
    (isPageReportDataLoading && isSettingsCompleted) ||
    isReportCsvDownLoading ||
    isTimelineCsvDownLoading

  return (
    <div>
      <PageHeader title={title} description={title} />
      {firstVisit && <Welcome loading={loading} />}
      {isLoading && <Loading />}

      {openedFilter && <CustomFilterUseContext onReset={actions.resetPaginate} onApply={actions.resetPaginate} />}
      {/* 高度な検索条件 */}
      {openedNarrowDown && (
        <PageReportNarrowDown
          opened={openedNarrowDown}
          disabled={disabledNarrowDown}
          items={narrowDownTemp}
          onClose={actions.narrowdown.onClose}
          onCancel={actions.narrowdown.onCancel}
          onApply={handleNarrowDownApply}
          onAdd={actions.narrowdown.onAdd}
          onDelete={actions.narrowdown.onDelete}
          onReset={handleNarrowDownReset}
          onOptionSelect={actions.narrowdown.onSelect}
          onInputChange={actions.narrowdown.onChange}
        />
      )}
      <PageLayout
        headerTitle={PAGE_TITLES.PAGE_REPORT}
        optionHidden={!initialized} // ロード中はオプション表示なし
        filterButtonHidden={false}
        baseUrl={baseUrl}
        filterApply={customFilterState.length > 0}
        isReportPage={true}
        onDateRangeApply={actions.resetPaginate}
        onGoalChange={actions.resetPaginate}
        onScopeTypeChange={actions.resetPaginate}
        toolbar={
          isSettingsCompleted && (
            <Toolbar
              onQuickFilterClick={actions.resetPaginate}
              canView={Authority.canViewReport(AccountInfo.permissions, projectId)}
              selectedDevice={deviceType}
              onFilterClick={customFilterActions.open}
              onChangeDevice={handleDeviceChange}
              toolMenuItems={[
                {
                  name: 'レポート（CSV）ダウンロード',
                  onClick: handleReportCsvDownloadClick,
                },
                {
                  name: '時系列（CSV）ダウンロード',
                  onClick: handleTimelineCsvDownloadClick,
                },
              ]}
            />
          )
        }
      >
        {!Authority.canViewReport(AccountInfo.permissions, projectId) && (
          <Container>
            <CanNotViewMessage />
          </Container>
        )}
        {!loading && Authority.canViewReport(AccountInfo.permissions, projectId) && (
          <Container ref={containerRef}>
            <div>
              <HelpBox>
                <HelpLink title={`ページレポートの詳しい見方`} link={`${HELP_LINKS.PAGE_REPORT_HOW_TO}`} />
              </HelpBox>
              <Box style={{ minWidth: `${gridLayout.minWidth}` }}>
                <NotSettingsMessages projectId={Number(projectId)} baseUrl={baseUrl} />
                {/* 組織のPVの上限を超えている */}
                {teamPvCount >= teamPvLimit && <PvLimitErrorBox pvLimit={teamPvLimit} />}
                {/* 汎用エラーメッセージ */}
                {warningMessage && <WarningBox>{warningMessage}</WarningBox>}
                {errorMessage && <ErrorBox>{errorMessage}</ErrorBox>}
                {isReportCsvDownloadError && <ErrorBox>レポート（CSV）ダウンロードに失敗しました。</ErrorBox>}
                {isTimelineCsvDownloadError && <ErrorBox>時系列（CSV）ダウンロードに失敗しました。</ErrorBox>}
                {isTimelineReportError && <ErrorBox>時系列データの取得に失敗しました。</ErrorBox>}
                <UrlParamsErrorMessages />
              </Box>

              {initialized && isSettingsCompleted && (
                <>
                  <AccordionBoxWrapper>
                    <HeaderShortAccordionBox
                      title="時系列グラフを表示"
                      icon={<BarChartAlt />}
                      accordionProps={{
                        expanded: timelineOpened && !isTimelineReportError,
                        onChange: handleTimelineAccordionChange,
                      }}
                      contentPadding={16}
                    >
                      {timelineOpened && (
                        <TimelineChart
                          projectId={Number(projectId)}
                          searchText={searchText}
                          narrowDownState={narrowDown}
                        />
                      )}
                    </HeaderShortAccordionBox>
                  </AccordionBoxWrapper>

                  <Box className="top">
                    <SearchWrapper>
                      {pageReportState.isNarrowDown ? (
                        <SearchApply>
                          <InputMask>検索オプション適用中</InputMask>
                          <LinkMessage onClick={actions.narrowdown.open}>編集</LinkMessage>
                          <LinkMessage onClick={() => handleNarrowDownReset()}>リセット</LinkMessage>
                        </SearchApply>
                      ) : (
                        <SearchNotApply>
                          <DesignedForm onSubmit={handleSearchApply}>
                            <DesignedInput
                              type="text"
                              value={searchTextTemp}
                              onChange={handleSearchUpdate}
                              iconRight={
                                //  検索状態によってクリアアイコンを表示
                                isSearch ? (
                                  <div style={{ display: 'flex' }}>
                                    <ClearButton onClick={() => handleSearchClear()} />
                                    <SearchButton onClick={handleSearchApply} />
                                  </div>
                                ) : (
                                  <SearchButton onClick={handleSearchApply} />
                                )
                              }
                            />
                          </DesignedForm>
                          <LinkMessageSearch onClick={actions.narrowdown.open}>検索オプション</LinkMessageSearch>
                        </SearchNotApply>
                      )}
                    </SearchWrapper>
                    <ControlWrapper>
                      <PageCapturedCheckbox onPageCapturedChange={actions.resetPaginate} />

                      {pageReportItems.length === 0 ? (
                        <Layer />
                      ) : (
                        <Layer>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Label>表示する件数:</Label>
                            <DesignedSelect
                              options={pageReportState.dispCountOptions}
                              value={dispCount}
                              onChange={handleDispChange}
                            />
                          </div>
                          <Label
                            style={{ marginLeft: '1rem' }}
                          >{`${itemCountFrom.toLocaleString()}～${itemCountTo.toLocaleString()}/${itemCount.toLocaleString()}件`}</Label>
                        </Layer>
                      )}
                    </ControlWrapper>
                  </Box>
                  {pageReportItems.length > 0 && (
                    <>
                      <Box>
                        <PageGrid
                          projectId={projectId}
                          items={pageReportItems}
                          pageReportsSummary={pageReportsSummary}
                          sortState={sortState}
                          topIndex={itemCountFrom}
                          baseUrl={baseUrl}
                          onSort={handleSortChange}
                        />
                      </Box>
                      <Box className="bottom">
                        <Layer>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Label>表示する件数:</Label>
                            <DesignedSelect
                              options={pageReportState.dispCountOptions}
                              value={dispCount}
                              onChange={handleDispChange}
                            />
                          </div>
                          <Label
                            style={{ marginLeft: '1rem' }}
                          >{`${itemCountFrom.toLocaleString()}～${itemCountTo.toLocaleString()}/${itemCount.toLocaleString()}件`}</Label>
                        </Layer>
                      </Box>
                      <Box>
                        <Paginate
                          forcePage={currentPage}
                          pageCount={page}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          disableInitialCallback={false}
                          onPageChange={handlePaginateChange}
                        />
                      </Box>
                    </>
                  )}
                </>
              )}
            </div>
          </Container>
        )}
      </PageLayout>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 8px 32px 0 32px;
`

const AccordionBoxWrapper = styled.div`
  margin-bottom: 32px;
  min-width: 900px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.top {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.7rem;
  }

  &.bottom {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.7rem;
  }
`

const HelpBox = styled(Box)`
  justify-content: flex-end;
  flex-direction: row;
  margin-bottom: 15px;
`

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: ${gridLayoutRowGroups.url};
  padding-right: 30px;
`

const SearchApply = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${gridLayoutRowGroups.withoutUrl};
  min-width: ${gridLayoutRowGroups.withoutUrl};
  max-width: ${gridLayoutRowGroups.withoutUrl};
`

const Layer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DesignedSelect = styled(Select)`
  width: 65px;
  height: 30px;
  font-size: 1rem;
  padding: 0;
`

const SearchNotApply = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const DesignedForm = styled.form`
  width: 100%;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 30px;
`

const InputMask = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 30px;
  font-size: 0.8rem;
  font-weight: 600;
  color: ${colors.white};
  background-color: ${colors.blue};
  border: 1px solid ${colors.gray300};
`

const SearchButton = styled(Search).attrs({
  size: 18,
  color: `${colors.gray600}`,
})`
  cursor: pointer;
`

const ClearButton = styled(CloseCircle).attrs({
  size: 18,
  color: `${colors.gray600}`,
})`
  cursor: pointer;
  margin-right: 0.3rem;
`

const Label = styled.div`
  font-size: 14px;
  line-height: 21px;
`

const LinkMessage = styled.a`
  font-size: 0.8rem;
  text-decoration: underline;
  cursor: pointer;
  color: ${colors.link.base};
  margin-left: 0.5rem;

  &:visited {
    color: ${colors.link.visited};
  }
`

const LinkMessageSearch = styled(LinkMessage)`
  white-space: nowrap;
`
