import React from 'react'
import styled from 'styled-components'
import { FunnelStepData } from '../../../util/hooks/api/FunnelReport/types'
import { colors } from '../../../styleConstants'
import { StepTransaction } from './StepTransaction'
import { InputErrorMessageWrapper, StepAccessCount, StepBox, StepTitle } from '../Report/Steps'
import {
  SolidCircleButton,
  SolidCircleButtonDarkBlue,
  SolidCircleButtonGray,
} from '../../../components/common/CircleButton'
import { Edit } from 'styled-icons/material-outlined'
import { DeleteBin5 } from 'styled-icons/remix-line'
import { Add } from 'styled-icons/remix-fill'
import { STEP_TYPES } from '../../../util/hooks/api/FunnelReport/constants'
import { InputErrorMessage } from '../../../components/errors/InputErrorMessage'

interface Props {
  step: FunnelStepData
  stepIndex: number
  lastStepIndex: number
  canAddStep: boolean
  handleOpenEditStepModal: (stepIndex: number, isInsertNewStep?: boolean) => void
  handleDeleteStep: (stepIndex: number) => void
  stepTitleRefs: React.MutableRefObject<(HTMLDivElement | null)[]>
  maxHeights: number[]
}

export const Step = ({
  step,
  stepIndex,
  lastStepIndex,
  canAddStep,
  handleOpenEditStepModal,
  handleDeleteStep,
  stepTitleRefs,
  maxHeights,
}: Props) => {
  const hasGoalError = step.stepType === STEP_TYPES.GOAL && !step.stepGoal?.goalId

  return (
    <div data-testid={`step-${stepIndex + 1}`}>
      {stepIndex > 0 && <StepTransaction transitionCount={null} transitionRate={null} />}

      <StepBoxEdit hasError={hasGoalError}>
        <StepControlButtons>
          <SolidCircleButton
            title="ステップを編集"
            onClick={() => {
              handleOpenEditStepModal(stepIndex)
            }}
            size={28}
          >
            <Edit size={16} />
          </SolidCircleButton>
          <SolidCircleButtonGray
            title="ステップを削除"
            onClick={() => {
              handleDeleteStep(stepIndex)
            }}
            size={28}
          >
            <DeleteBin5 size={16} />
          </SolidCircleButtonGray>
        </StepControlButtons>

        <StepTitle
          hasError={hasGoalError}
          ref={(el) => (stepTitleRefs.current[stepIndex] = el)}
          style={{ height: maxHeights[stepIndex] }}
        >
          <div>{stepIndex + 1}.</div>
          <div>{step.name}</div>
        </StepTitle>

        {hasGoalError ? (
          <InputErrorMessageWrapper>
            <InputErrorMessage>指定したゴールが削除されています。</InputErrorMessage>
          </InputErrorMessageWrapper>
        ) : (
          <StepAccessCount>-</StepAccessCount>
        )}

        {canAddStep && stepIndex !== lastStepIndex && (
          <StepInsertButton
            title="ステップを挿入"
            onClick={() => {
              handleOpenEditStepModal(stepIndex, true)
            }}
            size={32}
          >
            <Add size={20} />
          </StepInsertButton>
        )}
      </StepBoxEdit>
    </div>
  )
}

const show = `
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
`

const hide = `
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s linear;
`

const StepControlButtons = styled.div`
  ${hide}
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 3px;
  right: 3px;
  padding: 4px;
  border-radius: 2px;
  background: ${colors.white};
  box-shadow: 0px 2px 4px #00000029;
`

const StepInsertButton = styled(SolidCircleButtonDarkBlue)`
  ${hide}
  position: absolute;
  bottom: -15px;
  right: calc(50% - 16px);
`

const StepBoxEdit = styled(StepBox)`
  &:hover ${StepControlButtons}, &:hover ${StepInsertButton} {
    ${show}
  }
`
